import React, { FC } from 'react';
import { PageContent, SubHeader } from '../../../../appComponents';
import { AccountBalance as AccountBalanceIcon } from '@mui/icons-material';
const Exchanges: FC<{}> = () => {
  return (
    <>
      <SubHeader title={'Exchanges'} icon={AccountBalanceIcon} />
      <PageContent>Content Here</PageContent>
    </>
  );
};

export default Exchanges;
