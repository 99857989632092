import { BotFormValues } from '@trade4me/components';
import { fetcher } from '../..';

const API_ROUTE = 'api/research';

export const makeAResearch = ({
  exchangeName,
  pattern,
  pair,
  timeFrame,
  settings: { investment, investProfit, limitProfitPercent, stopLossPercent },
}: BotFormValues) =>
  fetcher('GET', `${API_ROUTE}/${exchangeName}/${pair.replace('/', '-')}/${timeFrame}/${pattern}`, {
    investment,
    investProfit,
    limitProfitPercent,
    stopLossPercent,
  });
