import React, { FC, useEffect } from 'react';

export const P404: FC<{}> = () => {
  useEffect(() => {}, []);
  return (
    <div>
      <div>404</div>
    </div>
  );
};

export default P404;
