import React, { FC, useState } from 'react';
import { BotForUi, BotSettings, calculatePercentDifference, DIRECTION } from '@trade4me/shared';
import { Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, Switch } from '@mui/material';
import { CoinWithBase } from '../CoinWithBase';
import {
  PlaylistAddCheck as PlaylistAddCheckIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { orange, green, grey } from '@mui/material/colors';
import { BotSettingsForm } from './../BotSettingsForm';
import { toNumber } from 'lodash';
import { BotFormValues } from './../';

export interface BotCardProps {
  bot: BotForUi;
  isTrading: boolean;
  onActiveChange: (id: string, isActive: boolean) => void;
  onResearch: (bot: BotFormValues) => void;
  onShowTrades: (id: string) => void;
  onSaveSettings: (id: string, settings: BotSettings) => void;
  onDeleteBot: (values: BotFormValues) => void;
}

const getStatusColor = (isActive: boolean, isTrading: boolean): string => {
  const statusColors: Record<string, string> = {
    onTrade: orange[300],
    active: green[500],
    notActive: grey[500],
  };

  if (isTrading) {
    return statusColors.onTrade;
  }

  return isActive ? statusColors.active : statusColors.notActive;
};

const getDirectionIcon = (direction: DIRECTION) => (direction === 'DOWN' ? <TrendingDownIcon /> : <TrendingUpIcon />);

export const BotCard: FC<BotCardProps> = ({
  bot,
  isTrading,
  onActiveChange,
  onResearch,
  onShowTrades,
  onSaveSettings,
  onDeleteBot,
}) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const { _id, pair, timeFrame, exchangeName, pattern, profit, active, settings } = bot;
  const { investment, initialInvestment } = settings;

  const handleActiveChange = (id: string, isActive: boolean) => {
    onActiveChange(id, isActive);
  };

  const handleSaveSettings = (settings: BotSettings) => {
    onSaveSettings(_id, settings);
    setShowSettings(false);
  };
  const handleRunResearch = (values: BotFormValues) => {
    onResearch(values);
  };

  const handleOpenSettings = () => {
    setShowSettings(!showSettings);
  };

  const handleOnDelete = (values: BotFormValues) => {
    onDeleteBot(values);
  };

  const coinArr = (pair || '').split('/');
  const base = coinArr[1].toLowerCase();
  const coin = coinArr[0].toLowerCase();
  const parsedTimeFrame = timeFrame.replace('_', '');
  const parsedProfit = toNumber(profit).toFixed(2);
  const parsedInvestment = toNumber(investment).toFixed(2);

  const defaultValues: BotFormValues = {
    pair,
    exchangeName,
    timeFrame,
    pattern,
    settings: {
      ...settings,
      investment: toNumber(parsedInvestment),
    },
  };
  return (
    <Card>
      <CardHeader
        avatar={<CoinWithBase coinName={coin} baseName={base} size={6} color={getStatusColor(active, isTrading)} />}
        title={pair.replace('/', ' / ')}
        subheader={
          <Grid container spacing={2} alignItems="center">
            <Grid item>{getDirectionIcon(bot.direction as DIRECTION)}</Grid>
            <Grid item xs>
              {parsedTimeFrame}
            </Grid>
            <Grid item>
              <Switch
                checked={active as boolean}
                onChange={event => handleActiveChange(bot._id, event.target.checked)}
              />
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container direction="column" spacing={2} alignContent="center" alignItems="center">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>Profit: {parsedProfit}</Grid>
              <Grid item>/</Grid>
              <Grid item>{`${calculatePercentDifference(initialInvestment, initialInvestment + profit)}%`}</Grid>
            </Grid>
          </Grid>
          <Grid item>Invs: {parsedInvestment}</Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container direction="row-reverse" spacing={2} alignItems={'center'}>
          <Grid item>
            <IconButton color="primary" onClick={() => onShowTrades(bot._id)} size="large">
              <PlaylistAddCheckIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <IconButton color="primary" onClick={handleOpenSettings} size="large">
              <SettingsIcon />
              {!showSettings && <ExpandMoreIcon onClick={handleOpenSettings} />}
              {showSettings && <ExpandLessIcon onClick={handleOpenSettings} />}
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={showSettings} unmountOnExit>
        <BotSettingsForm
          defaultValues={defaultValues}
          onSaveSettings={handleSaveSettings}
          onRunResearch={handleRunResearch}
          onDelete={handleOnDelete}
        />
      </Collapse>
    </Card>
  );
};
