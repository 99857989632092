import React, { FC } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
  Home as HomeIcon,
  Adb as BotIcon,
  AccountBalance as AccountBalanceIcon,
  TwoWheeler as ResearchIcon,
  MonetizationOn as DonationIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';

import { AppWrapper, MenuItem } from './AppWrapper';

import Home from './routes/Home/Home';
import Bots from './routes/Bots/Bots';
import Exchanges from './routes/Exchanges/Exchanges';
import Research from './routes/Research/Research';
import Settings from './routes/Settings/Settings';
import Donation from './routes/Donation/Donation';
import { ResearchResultsDialog } from './routes/Research/ResearchResultsDialog';

const appTitle = 'Trade 4 Me! (Crypto)';

export const App: FC<{}> = () => {
  const navigate = useNavigate();
  const currentRoute = '';
  const menuItems: MenuItem[] = [
    {
      text: 'Home',
      icon: HomeIcon,
      route: `/app`,
    },
    {
      text: 'Bots',
      icon: BotIcon,
      route: `/app/bots`,
    },
    {
      text: 'Exchanges',
      icon: AccountBalanceIcon,
      route: `/app/exchanges`,
    },
    {
      text: 'Research',
      icon: ResearchIcon,
      route: `/app/research`,
    },
    {
      text: 'Donation',
      icon: DonationIcon,
      route: `/app/donation`,
    },
    {
      text: 'Settings',
      icon: SettingsIcon,
      route: `/app/settings`,
    },
  ];

  const handleMenuIconSelected = (route: string) => {
    navigate(route);
  };

  return (
    <AppWrapper
      appTitle={appTitle}
      menuItems={menuItems}
      currentRoute={currentRoute}
      onMenuItemSelected={handleMenuIconSelected}
    >
      <ResearchResultsDialog />
      <Routes>
        <Route index element={<Home />} />
        <Route path={`bots`} element={<Bots />} />
        <Route path={`exchanges`} element={<Exchanges />} />
        <Route path={`research`} element={<Research />} />
        <Route path={`settings`} element={<Settings />} />
        <Route path={`donation`} element={<Donation />} />
      </Routes>
    </AppWrapper>
  );
};

export default App;
