import React, { FC, useState, useMemo } from 'react';
import { ALGO_PATTERNS, BotEntity, BotSettings, MyExchange, TIME_FRAME } from '@trade4me/shared';
import { capitalize, startCase, isEmpty } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { TrendingUp as TrendingUpIcon, TrendingDown as TrendingDownIcon } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  Typography,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { CryptoIcon, PairPicker } from '..';
import { grey } from '@mui/material/colors';

export interface BotSettingsFormValue extends BotSettings {}

export interface BotFormValues
  extends Pick<BotEntity, 'pair' | 'exchangeName' | 'timeFrame' | 'pattern' | 'settings'> {}
export interface BotPropertiesFormProps {
  exchanges: Partial<MyExchange>[];
  onValuesChanges: (values: BotFormValues) => void;
  values?: BotFormValues;
  isEditBotMode?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

export const BotPropertiesForm: FC<BotPropertiesFormProps> = ({
  values,
  exchanges,
  onValuesChanges,
  isEditBotMode = false,
}) => {
  const classes = useStyles();

  const defaultSelectedExchange = useMemo(() => {
    return values.exchangeName ? exchanges.find(({ name }) => name === values.exchangeName) : exchanges[0];
  }, []);

  const [selectedExchange, setSelectedExchange] = useState<Partial<MyExchange>>(defaultSelectedExchange);

  const handleValueChange = (values: BotFormValues) => {
    onValuesChanges(values);
  };

  const handleExchangeSelectChange = (e: any) => {
    const exchange = exchanges.find(exchange => exchange.name === e.target.value);
    const newValues: BotFormValues = { ...values, exchangeName: exchange.name };
    setSelectedExchange(exchange);
    handleValueChange(newValues);
  };

  const handlePairChanged = (pair: string) => {
    const newValues: BotFormValues = { ...values, pair };
    handleValueChange(newValues);
  };

  const handleTimeFrameSelectChange = (e: any) => {
    const timeFrame = e.target.value;
    const newValues: BotFormValues = { ...values, timeFrame };
    handleValueChange(newValues);
  };

  const handlePatternSelectChange = (e: any) => {
    const pattern = e.target.value;
    const newValues: BotFormValues = { ...values, pattern };
    handleValueChange(newValues);
  };

  const handleInputChange = (attr: string) => (event: any) => {
    const value = event.target.value;
    const newValues: BotFormValues = { ...values, settings: { ...values.settings, [attr]: value } };
    handleValueChange(newValues);
  };

  const handleCheckboxChange = (attr: string) => (event: any) => {
    const value = event.target.checked;
    const obj = {
      [attr]: value,
    };
    const newValues: BotFormValues = { ...values, settings: { ...values.settings, [attr]: value } };
    handleValueChange(newValues);
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      {!isEmpty(exchanges) && !isEditBotMode && (
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="exchange-label">Exchange</InputLabel>
            <Select
              labelId="exchange-label"
              value={values.exchangeName || exchanges[0].name}
              onChange={handleExchangeSelectChange}
              label="Exchange"
            >
              {exchanges.map((exchange: MyExchange) => (
                <MenuItem key={exchange._id} value={exchange.name}>
                  {capitalize(exchange.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {!isEmpty(selectedExchange?.pairs) && !isEditBotMode && (
        <Grid item sm={8} xs={12}>
          <PairPicker
            onValueChange={handlePairChanged}
            pairs={selectedExchange.pairs}
            value={values.pair || exchanges?.[0].pairs?.[0]}
          />
        </Grid>
      )}
      {!isEditBotMode && (
        <Grid item sm={4} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="timeFrame-label">Time Frame</InputLabel>
            <Select
              labelId="timeFrame-label"
              value={values.timeFrame}
              onChange={handleTimeFrameSelectChange}
              label="Time Frame"
            >
              {(selectedExchange.timeFrames || []).map((timeFrame: TIME_FRAME) => (
                <MenuItem key={TIME_FRAME[timeFrame]} value={TIME_FRAME[timeFrame]}>
                  {TIME_FRAME[timeFrame].replace('_', '')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {!isEditBotMode && (
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="Pattern-label">Pattern</InputLabel>
            <Select labelId="Pattern-label" value={values.pattern} onChange={handlePatternSelectChange} label="Pattern">
              {(Object.values(ALGO_PATTERNS) || []).map((pattern: ALGO_PATTERNS) => (
                <MenuItem key={pattern} value={pattern}>
                  <ListItemIcon>
                    {pattern.includes('Down') && <TrendingDownIcon fontSize={'small'} />}
                    {!pattern.includes('Down') && <TrendingUpIcon fontSize={'small'} />}
                  </ListItemIcon>
                  <Typography variant="inherit">{startCase(pattern.replace('Up', '').replace('Down', ''))}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item sm={6} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="investment">Investment</InputLabel>
          <OutlinedInput
            type="number"
            id="stopLossPercent"
            value={values.settings.investment}
            onChange={handleInputChange('investment')}
            startAdornment={
              <InputAdornment position="start">
                <CryptoIcon size={2} color={grey[500]} name={values.pair.split('/')[1]} />
              </InputAdornment>
            }
            label={'Investment'}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="stopLossPercent">Stop Loss</InputLabel>
          <OutlinedInput
            type="number"
            id="stopLossPercent"
            value={values.settings.stopLossPercent}
            onChange={handleInputChange('stopLossPercent')}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            label={'Stop Loss'}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="limitProfitPercent">Limit Profit</InputLabel>
          <OutlinedInput
            type="number"
            id="limitProfitPercent"
            value={values.settings.limitProfitPercent}
            onChange={handleInputChange('limitProfitPercent')}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
            label={'Limit Profit'}
          />
        </FormControl>
      </Grid>
      <Grid item sm={6} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              name="investProfit"
              checked={values.settings.investProfit}
              onChange={handleCheckboxChange('investProfit')}
            />
          }
          label="Re-Invest Profit"
        />
      </Grid>
    </Grid>
  );
};
