import React, { FC, useState } from 'react';
import { BotSettings } from '@trade4me/shared';

import { TrendingUp as TrendingUpIcon, Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, CardActions, CardContent, Grid, IconButton } from '@mui/material';

import { BotPropertiesForm, BotFormValues } from './../BotPropertiesForm';
export interface BotSettingsFormProps {
  defaultValues: BotFormValues;
  onSaveSettings: (settings: BotSettings) => void;
  onRunResearch: (values: BotFormValues) => void;
  onDelete: (values: BotFormValues) => void;
}

export const BotSettingsForm: FC<BotSettingsFormProps> = ({
  defaultValues,
  onSaveSettings,
  onRunResearch,
  onDelete,
}) => {
  const [values, setValues] = useState<BotFormValues>(defaultValues);

  const handleValueChange = (values: BotFormValues) => {
    setValues(values);
  };

  const handleSaveButtonClicked = () => {
    onSaveSettings({ ...values.settings, initialInvestment: 0 });
  };

  const handleRunResearch = () => {
    onRunResearch(values);
  };

  const handleDeleteButtonClicked = () => {
    onDelete(values);
  };

  return (
    <>
      <CardContent>
        <BotPropertiesForm isEditBotMode={true} values={values} onValuesChanges={handleValueChange} exchanges={[]} />
      </CardContent>
      <CardActions>
        <Grid container direction="row-reverse" alignItems="center" spacing={1}>
          <Grid item>
            <Button size="small" variant="outlined" startIcon={<SaveIcon />} onClick={handleSaveButtonClicked}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              color={'secondary'}
              startIcon={<DeleteIcon />}
              onClick={handleDeleteButtonClicked}
            >
              Del
            </Button>
          </Grid>
          <Grid item xs>
            <IconButton color="primary" onClick={handleRunResearch} size="large">
              <TrendingUpIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </>
  );
};
