import React, { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CssBaseline, LinearProgress } from '@mui/material';

import './App.css';

import Login from './mainRoutes/Login/Login';
import AuthenticatedApp from './mainRoutes/App/App';
import P404 from './mainRoutes/P404/P404';

const AppBase: FC<{}> = () => {
  return (
    <CssBaseline>
      <Suspense fallback={<LinearProgress color="secondary" />}>
        <Routes>
          <Route index element={<AuthenticatedApp />} />
          <Route path={'app/*'} element={<AuthenticatedApp />} />
          <Route path={'login'} element={<Login />} />
          <Route path={'*'} element={<P404 />} />
        </Routes>
      </Suspense>
    </CssBaseline>
  );
};
export default AppBase;
