export enum DIRECTION {
  UP = 'UP',
  DOWN = 'DOWN',
}

export enum ALGO_PATTERNS {
  THREE_BARS_IN_A_ROW_UP = 'threeStrateBarsInARowUp',
  THREE_BARS_IN_A_ROW_DOWN = 'threeStrateBarsInARowDown',
  THREE_BARS_PLAY = 'threeBarsPlay',
}

const directions = {
  [ALGO_PATTERNS.THREE_BARS_IN_A_ROW_UP]: DIRECTION.UP,
  [ALGO_PATTERNS.THREE_BARS_PLAY]: DIRECTION.UP,
  [ALGO_PATTERNS.THREE_BARS_IN_A_ROW_DOWN]: DIRECTION.DOWN,
};

export const getDirection = (pattern: ALGO_PATTERNS) => directions[pattern];
