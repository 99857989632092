import { Auth0Lock } from 'auth0-lock';

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
const domain = process.env.REACT_APP_AUTH0_DOMAIN as string;
const clientURL = process.env.REACT_APP_CLIENT_URL;

const options: Auth0LockConstructorOptions = {
  container: '',
  allowSignUp: false,
  auth: {
    redirectUrl: `${clientURL}/callback.html`, // If not specified, defaults to the current page
    responseType: 'token id_token',
    params: {
      scope: 'openid email user_metadata app_metadata picture', // Learn about scopes: https://auth0.com/docs/scopes
    },
  },
  language: 'en',
  theme: {
    logo: 'https://trade4me.app/logo.svg',
    primaryColor: '#c2185b',
  },
  languageDictionary: {
    title: 'Trade4me',
  },
};

export const loginLock = (container: string) => new Auth0Lock(clientId, domain, { container, ...options });
