import { fetcher } from '../..';
import { BotForUi, BotSettings } from '@trade4me/shared';
import { pick } from 'lodash';

export interface NewBot extends Pick<BotForUi, 'pair' | 'timeFrame' | 'pattern' | 'active' | 'exchangeName'> {
  exchangeId: string;
  settings: Omit<BotSettings, 'initialInvestment'>;
}

export interface UpdateBot {
  active?: boolean;
  settings?: Omit<BotSettings, 'initialInvestment'>;
}

const API_ROUTE = 'api/bots';

export const getBotsByEntity = async () => {
  const bots = await fetcher('GET', API_ROUTE);
  return bots as Record<string, BotForUi>;
};

export const addNewBot = async (obj: NewBot) => {
  const newBot = await fetcher('POST', API_ROUTE, obj);
  return newBot as BotForUi;
};
export const updateBot = (botId: string, obj: UpdateBot) => {
  if (obj.settings)
    obj.settings = pick(obj.settings, ['investProfit', 'investment', 'limitProfitPercent', 'stopLossPercent']);

  return fetcher('PUT', `${API_ROUTE}/${botId}`, obj);
};
export const removeBot = (botId: string) => fetcher('DELETE', `${API_ROUTE}/${botId}`);
