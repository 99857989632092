import React, { FC } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BotFormValues, BotPropertiesForm } from '@trade4me/components';
import { myExchangesSelector } from '../../../../store/exchanges.store';
import {
  commonBotValues,
  isShowResearchLoaderAtom,
  researchResultsAtom,
  isShowResearchResultsPageAtom,
} from '../../../../store';
import { isEmpty } from 'lodash';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { makeAResearch } from '../../../../services';
import { toast } from 'react-toastify';
import { ResearchResults } from '@trade4me/shared';
import { PageContent, SubHeader } from '../../../../appComponents';
import { TwoWheeler as ResearchIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {
    width: `calc(100vw - ${theme.spacing(6)})`,
  },
}));

const Research: FC<{}> = () => {
  const classes = useStyles();
  const myExchanges = useRecoilValue(myExchangesSelector);
  const [researchValues, setResearchValues] = useRecoilState(commonBotValues);
  const [isShowResearchLoader, setIsShowResearchLoader] = useRecoilState(isShowResearchLoaderAtom);
  const setIsShowResearchResultsPage = useSetRecoilState(isShowResearchResultsPageAtom);
  const setResearchResults = useSetRecoilState(researchResultsAtom);

  const handleBotFormValuesChanges = (values: BotFormValues) => {
    setResearchValues(values);
  };

  const handleGoButtonClicked = async () => {
    try {
      setIsShowResearchLoader(true);
      const response = (await makeAResearch({
        ...researchValues,
        exchangeName: researchValues.exchangeName || myExchanges[0].name,
        pair: researchValues.pair || myExchanges[0].pairs[0],
      })) as ResearchResults;
      if (response.trades.length) {
        setResearchResults(response);
        setIsShowResearchResultsPage(true);
      } else {
        toast.info('No results for this research');
      }
    } catch (err) {
      console.log(err);
      toast.error('Problem making a research');
    }
    setIsShowResearchLoader(false);
  };

  return (
    <>
      <SubHeader
        title={'Research'}
        icon={ResearchIcon}
        rightSideComponent={<Button onClick={handleGoButtonClicked}>Go!</Button>}
      />
      <PageContent>
        <div>
          {!isEmpty(myExchanges) && !isEmpty(researchValues) && (
            <BotPropertiesForm
              exchanges={myExchanges}
              onValuesChanges={handleBotFormValuesChanges}
              values={researchValues}
            />
          )}
        </div>
      </PageContent>
    </>
  );
};

export default Research;
