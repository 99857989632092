import React, { FC, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import genericIcon from '@iconify/icons-cryptocurrency/generic.js';

export interface CryptoIconProps {
  name: string;
  size: number;
  color?: string;
}

const useStyles = makeStyles((theme: any) => ({
  size: ({ size = 1 }: Partial<CryptoIconProps>) => ({
    width: theme.spacing(size),
    height: theme.spacing(size),
  }),
}));

export const CryptoIcon: FC<CryptoIconProps> = ({ name = 'N/A', size, color = '#000' }) => {
  const classes = useStyles({ size });
  const [icon, setIcon] = useState(genericIcon);

  useEffect(() => {
    (async () => {
      try {
        const namedIcon = require(`@iconify/icons-cryptocurrency/${name.toLowerCase()}.js`);
        setIcon(namedIcon.default);
      } catch (err) {
        //do nothing
      }
    })();
  }, [name]);

  return (
    <Tooltip title={name.toUpperCase()} arrow>
      <Icon color={color} className={classes.size} icon={icon} />
    </Tooltip>
  );
};
