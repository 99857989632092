import { FC, forwardRef, useEffect, useState } from 'react';
import { BotPropertiesForm, BotFormValues } from '@trade4me/components';
import { AppBar, Button, Dialog, IconButton, Slide, Toolbar, Typography, Grid, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon } from '@mui/icons-material';
import { ALGO_PATTERNS, MyExchange, TIME_FRAME } from '@trade4me/shared';
import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';
import { commonBotValues } from '../../../../store';

export interface NewBotDialogProps {
  isOpen: boolean;
  exchanges: Partial<MyExchange>[];
  onCreateNewBot: (values: BotFormValues) => void;
  onCancel: () => void;
}

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
  },
}));

const Transition: FC<any> = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export const AddNewBotDialog: FC<NewBotDialogProps> = ({ isOpen, onCreateNewBot, onCancel, exchanges = [] }) => {
  const classes = useStyles();
  const [newBotValues, setNewBotValues] = useRecoilState(commonBotValues);
  const handleOnSave = () => {
    newBotValues && onCreateNewBot(newBotValues);
  };

  const handleBotFormValuesChanges = (values: BotFormValues) => {
    setNewBotValues(values);
  };

  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={onCancel} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Grid container alignItems={'center'}>
              <Grid item>
                <IconButton edge="start" color="inherit" onClick={onCancel} aria-label="close" size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Typography variant="h6">Create New Bot</Typography>
              </Grid>
              <Grid item>
                <Button autoFocus color="inherit" onClick={handleOnSave}>
                  save
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {!isEmpty(newBotValues) && !isEmpty(exchanges) && (
            <BotPropertiesForm
              exchanges={exchanges}
              onValuesChanges={handleBotFormValuesChanges}
              values={newBotValues}
            />
          )}
          {isEmpty(newBotValues) && <div>Please add an Exchange first</div>}
        </DialogContent>
      </Dialog>
    </div>
  );
};
