import { DIRECTION } from 'src';

export const calculatePercentDifference = (oldNumber: number, newNumber: number) => {
  const decreaseValue: number = oldNumber - newNumber;
  const percent: number = (decreaseValue / oldNumber) * 100;
  return Number((percent > 0 ? percent : percent * -1).toFixed(2));
};

export const addPercentToValue = (val: number, percent: number) => {
  const portion = (val * percent) / 100;
  return portion + val;
};

export const millisecondsToReadableTime = (milliseconds: number) => {
  const date = new Date(milliseconds);
  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = '0' + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = '0' + date.getSeconds();
  const dateString = date.toDateString();

  // Will display time in 10:30:23 format
  return `${dateString} - ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
};

export const unixTimeToReadableTime = (unixTime: number) => {
  return millisecondsToReadableTime(unixTime * 1000);
};

export const dateAsStringToReadableTime = (dateAsString: string) => {
  return millisecondsToReadableTime(Date.parse(dateAsString));
};

export const calculateProfit = (buyPrice: number, sellPrice: number, amount: number): number => {
  return (sellPrice - buyPrice) * amount;
};
