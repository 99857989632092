import { BotFormValues } from '@trade4me/components';
import { ALGO_PATTERNS, BotForUi, TIME_FRAME } from '@trade4me/shared';
import { values } from 'lodash';
import { atom, selector } from 'recoil';

export const allBotsObjAtom = atom<Record<string, BotForUi>>({
  key: 'allBotsObjAtom',
  default: {},
});

export const allBotsArrSelector = selector<BotForUi[]>({
  key: 'allBotsArrSelector',
  get: ({ get }) => {
    return values(get(allBotsObjAtom));
  },
});

export const filterBotsStrAtom = atom<string>({
  key: 'filterBotsStrAtom',
  default: '',
});

export const filteredBotsSelector = selector<BotForUi[]>({
  key: 'filteredBotsSelector',
  get: ({ get }) => {
    const allBotsArr = get(allBotsArrSelector);
    const str = get(filterBotsStrAtom);
    if (!str) return allBotsArr;
    return allBotsArr.filter(bot => bot.pair.toLowerCase().includes(str.toLowerCase()));
  },
});

export const botsByExchangeSelector = selector<Record<string, BotForUi[]>>({
  key: 'botsByExchangeSelector',
  get: ({ get }) => {
    const filteredBots = get(filteredBotsSelector);
    return filteredBots.reduce((acc: Record<string, BotForUi[]>, bot) => {
      acc[bot.exchangeName] = acc[bot.exchangeName] || [];
      acc[bot.exchangeName].push(bot);
      return acc;
    }, {});
  },
});

export const commonBotValues = atom<BotFormValues>({
  key: 'commonBotValues',
  default: {
    pair: '',
    exchangeName: '',
    timeFrame: TIME_FRAME._1h,
    pattern: ALGO_PATTERNS.THREE_BARS_IN_A_ROW_UP,
    settings: {
      initialInvestment: 50,
      investment: 50,
      investProfit: false,
      limitProfitPercent: 10,
      stopLossPercent: 40,
    },
  },
});
