import { fetcher } from '../..';
import { ExchangeEntity } from '@trade4me/shared';

interface UpdateExchange extends Omit<ExchangeEntity, 'name'> {}

const API_ROUTE = 'api/exchanges';

export const getExchanges = () => fetcher('GET', API_ROUTE);
export const addNewExchange = (obj: ExchangeEntity) => fetcher('POST', API_ROUTE, obj);
export const updateExchange = (exchangeId: string, obj: UpdateExchange) =>
  fetcher('PUT', `${API_ROUTE}/${exchangeId}`, obj);
export const removeExchange = (exchangeId: string) => fetcher('DELETE', `${API_ROUTE}/${exchangeId}`);

export const getSupportedExchanges = () => fetcher('GET', `${API_ROUTE}/public/getAllSupportedExchanges`);
export const getSupportedPairs = (exchangeName: string) =>
  fetcher('GET', `${API_ROUTE}/public/getSupportedPairs/${exchangeName}`);

export const getMyExchanges = () => fetcher('GET', `${API_ROUTE}/myExchanges`);
