import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DEFAULT_DEFAULT_SUBTITLE_HEIGHT_SPACING } from '..';

export interface PageContentProps {
  heightSpacing?: number;
}

const useStyles = makeStyles(theme => ({
  root: ({ heightSpacing = DEFAULT_DEFAULT_SUBTITLE_HEIGHT_SPACING }: Partial<PageContentProps>) => ({
    marginTop: theme.spacing(heightSpacing),
  }),
}));

export const PageContent: FC<PageContentProps> = ({ children, heightSpacing }) => {
  const classes = useStyles({ heightSpacing });

  return <div className={classes.root}>{children}</div>;
};
