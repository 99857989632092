import React, { FC } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Avatar, Badge } from '@mui/material';
import { CryptoIcon } from '../CryptoIcon';

export interface CoinWithBaseProps {
  baseName: string;
  coinName: string;
  size: number;
  color?: string;
}

const useStyles = makeStyles((theme: any) => ({
  size: ({ size = 1 }: Partial<CoinWithBaseProps>) => ({
    width: theme.spacing(size - 2),
    height: theme.spacing(size - 2),
  }),
  badgeWrapper: ({ size = 1 }: Partial<CoinWithBaseProps>) => ({
    border: '1px solid white',
    borderRadius: theme.spacing(size),
  }),
  coinWrapper: ({ size = 1 }: Partial<CoinWithBaseProps>) => ({
    border: '1px solid white',
    borderRadius: theme.spacing(size),
    backgroundColor: 'white',
  }),
}));

export const CoinWithBase: FC<CoinWithBaseProps> = ({ baseName, coinName, size, color }) => {
  const classes = useStyles({ size });
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        <Avatar className={classes.size}>
          <CryptoIcon name={baseName} size={size} color={color} />
        </Avatar>
      }
    >
      <CryptoIcon name={coinName} size={size} color={color} />
    </Badge>
  );
};
