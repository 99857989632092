import axios, { Method, AxiosRequestConfig } from 'axios';
import store from 'store';
import qs from 'qs';
import { isEmpty } from 'lodash';

export const fetcher = async (method: Method, url: string, data?: Record<string, any>) => {
  const apiURL = process.env.REACT_APP_BACKEND_URL;

  const options: AxiosRequestConfig = {
    method,
    url: `${apiURL}/${url}`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${store.get('token')}`,
    },
  };

  if (method.toLocaleLowerCase() !== 'get' && data) {
    options.data = data;
  }

  if (method.toLocaleLowerCase() === 'get' && data && !isEmpty(data)) {
    options.url = `${apiURL}/${url}?${qs.stringify(data)}`;
  }

  try {
    const res = await axios(options);
    return res?.data;
  } catch (resError: any) {
    const status = resError?.response?.status;

    if (status === 401) {
      store.remove('token');
      window.location.href = `${window.location.origin}/login`;
    }
    return Promise.reject(resError.response);
  }
};
