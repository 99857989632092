import React, { FC } from 'react';
import { noop } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { calculatePercentDifference, calculateProfit, TradeForUi, TRADE_STATUS } from '@trade4me/shared';
import { orange } from '@mui/material/colors';
import { toNumber } from 'lodash';
import { CoinWithBase } from './../CoinWithBase';
import { DefaultTheme } from '@mui/styles';

export interface TradeCardProps {
  isResearch?: boolean;
  trade: TradeForUi;
  onClick?: (trade: TradeForUi) => void;
}

const getStatusColor = (status: TRADE_STATUS, profit: number, theme: any): string => {
  switch (status) {
    case TRADE_STATUS.CANCELED:
      return theme.palette.grey[500];
    case TRADE_STATUS.OPEN:
      return orange[300];
    case TRADE_STATUS.CLOSED: {
      return profit <= 0 ? theme.palette.error.light : theme.palette.success.light;
    }
    case TRADE_STATUS.FAILED:
      return theme.palette.info.dark;
    default:
      return theme.palette.common.white;
  }
};

const useStyles = makeStyles(theme => ({
  root: ({ status, profit }: Partial<TradeForUi>) => ({
    backgroundColor: getStatusColor(status, profit, theme),
  }),
  title: {
    fontSize: '12pt',
  },
  pairTitle: {
    fontSize: '14pt',
  },
  pos: {
    marginBottom: 12,
  },
}));

export const TradeCard: FC<TradeCardProps> = ({ isResearch = false, trade, onClick = noop }) => {
  const { _id, pair, timeFrame, status, amount, buyPrice, sellPrice, leverage, investment } = trade;

  const profit = status === TRADE_STATUS.OPEN ? calculateProfit(buyPrice, sellPrice, amount) : trade.profit;

  const classes = useStyles({ status, profit });

  const coinArr = (pair || '').split('/');
  const base = coinArr[1].toLowerCase();
  const coin = coinArr[0].toLowerCase();
  const parsedTimeFrame = timeFrame.replace('_', '');
  const parsedProfit = toNumber(profit).toFixed(base.includes('usd') ? 2 : 5);
  const parsedInvestment = toNumber(investment).toFixed(2);

  return (
    <Card className={classes.root}>
      <CardContent>
        {!isResearch && (
          <Typography className={classes.pairTitle} variant="h5" component="h2" gutterBottom>
            <Grid container spacing={1} alignContent={'center'} alignItems={'center'}>
              <Grid item>
                <CoinWithBase coinName={coin} baseName={base} size={5} />
              </Grid>
              <Grid item>{pair}</Grid>
            </Grid>
          </Typography>
        )}
        <Typography className={classes.title}>
          {status !== TRADE_STATUS.CANCELED && (
            <Grid container spacing={1}>
              <Grid item>
                {status === TRADE_STATUS.OPEN && <>Expected</>} Profit: {parsedProfit}
                {base.toUpperCase()}
              </Grid>
              <Grid item>/</Grid>
              <Grid item>{`${calculatePercentDifference(investment, investment + profit)}%`}</Grid>
            </Grid>
          )}
          {status === TRADE_STATUS.CANCELED && 'CANCELED'}
        </Typography>

        <Typography className={classes.pos} color="textSecondary">
          Invs: {parsedInvestment}
          {base.toUpperCase()}
        </Typography>

        <Typography component="p">Buy Price: {buyPrice}</Typography>
        <Typography variant="body2" className={classes.pos} color="textSecondary">
          {trade.creationDate}
        </Typography>
        {status !== TRADE_STATUS.CANCELED && (
          <>
            <Typography component="p">Sell Price: {sellPrice}</Typography>
            <Typography variant="body2" className={classes.pos} color="textSecondary">
              {trade.closeDate}
            </Typography>{' '}
          </>
        )}
      </CardContent>
    </Card>
  );
};
