import { ResearchResults, TradeForUi } from '@trade4me/shared';
import { atom } from 'recoil';

export const researchResultsAtom = atom<ResearchResults>({
  key: 'researchResults',
  default: {
    profitAmount: 0,
    researchStartDate: '',
    researchEndDate: '',
    trades: [],
  },
});

export const isShowResearchLoaderAtom = atom<boolean>({
  key: 'isShowResearchLoader',
  default: false,
});

export const isShowResearchResultsPageAtom = atom<boolean>({
  key: 'isShowResearchResultsPage',
  default: false,
});
