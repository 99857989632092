import React, { FC, useEffect } from 'react';
import { loginLock } from './auth0-service';

const LOCK_CONTAINER_ID = 'app-login';

export const Login: FC<{}> = () => {
  useEffect(() => {
    const lock = loginLock(LOCK_CONTAINER_ID);
    lock.show();
  }, []);
  return (
    <div>
      <div id={LOCK_CONTAINER_ID}></div>
    </div>
  );
};
export default Login;
