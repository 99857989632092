import { MyExchange } from '@trade4me/shared';
import { atom, selector } from 'recoil';
import { getMyExchanges } from '../services';

export const myExchangesSelector = selector<MyExchange[]>({
  key: 'myExchangesSelector',
  get: async ({ get }) => {
    const response = await getMyExchanges();
    return response;
  },
});

export const allExchangesAtom = atom<MyExchange[]>({
  key: 'allExchangesAtom',
  default: [],
});
