import React, { FC, ReactNode } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { SvgIconComponent } from '@mui/icons-material';

export interface SubHeaderProps {
  title: string;
  rightSideComponent?: ReactNode;
  icon?: SvgIconComponent;
  backgroundColor?: string;
  heightSpacing?: number;
}

export const DEFAULT_DEFAULT_SUBTITLE_HEIGHT_SPACING: number = 5;

const useStyles = makeStyles(theme => ({
  root: ({
    backgroundColor = theme.palette.success.light,
    heightSpacing = DEFAULT_DEFAULT_SUBTITLE_HEIGHT_SPACING,
  }: Partial<SubHeaderProps>) => ({
    backgroundColor: backgroundColor,
    color: theme.palette.common.white,
    height: theme.spacing(heightSpacing),
    display: 'flex',
    columnGap: theme.spacing(1),
    position: 'fixed',
    width: '100%',
    zIndex: 3,
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}`,
    top: theme.spacing(7),
  }),
  title: {
    flexGrow: 1,
  },
}));

export const SubHeader: FC<SubHeaderProps> = ({
  icon: Icon,
  title,
  rightSideComponent,
  heightSpacing,
  backgroundColor,
}) => {
  const classes = useStyles({ heightSpacing, backgroundColor });

  return (
    <div className={classes.root}>
      {Icon && <div>{<Icon />}</div>}
      <div className={classes.title}>{title}</div>
      {rightSideComponent && <div>{rightSideComponent}</div>}
    </div>
  );
};
