import React, { StrictMode } from 'react';
import { Trade4meTheme } from '@trade4me/components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import AppBase from './AppBase';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';

ReactDOM.render(
  <StrictMode>
    <Trade4meTheme>
      <ToastContainer autoClose={2000} />
      <Router>
        <RecoilRoot>
          <AppBase />
        </RecoilRoot>
      </Router>
    </Trade4meTheme>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
