import React, { FC, useState, useCallback } from 'react';
import { Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { CoinWithBase } from '..';

export interface PairPickerProps {
  pairs: string[];
  onValueChange?: (pair: string) => void;
  value: string;
}

export const PairPicker: FC<PairPickerProps> = ({ pairs, value, onValueChange }) => {
  const handlePairSelectChange = e => {
    onValueChange(e.target.innerText || '');
  };

  const arr = value.split('/');
  const baseName = value.includes('/') ? arr[1] : '';
  const coinName = value.includes('/') ? arr[0] : '';

  return (
    <>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>
          <CoinWithBase baseName={baseName} coinName={coinName} size={5} />
        </Grid>
        <Grid item xs>
          <Autocomplete
            fullWidth
            id="combo-box-demo"
            getOptionLabel={option => option}
            value={value}
            options={pairs}
            renderInput={params => <TextField {...params} label="Select Pair" variant="outlined" />}
            onChange={handlePairSelectChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
