import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { isEmpty, keys, startCase } from 'lodash';
import { Search as SearchIcon, Add as AddIcon, Adb as BotIcon } from '@mui/icons-material';
import { Badge, Chip, OutlinedInput, Fab, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { BotCard, BotFormValues } from '@trade4me/components';
import { BotForUi, BotSettings, MyExchange } from '@trade4me/shared';

import { allBotsObjAtom, botsByExchangeSelector, filterBotsStrAtom } from '../../../../store';

import { addNewBot, getBotsByEntity, NewBot, updateBot } from '../../../../services';
import { AddNewBotDialog } from './AddNewBotDialog';
import { myExchangesSelector } from '../../../../store/exchanges.store';
import { SubHeader, PageContent } from '../../../../appComponents';

export const addNewBotFromValues = async (
  values: BotFormValues,
  myExchanges: MyExchange[],
  allBots: Record<string, BotForUi>,
  setAllBots: SetterOrUpdater<Record<string, BotForUi>>,
) => {
  const promiseForTostify = async () => {
    const currentExchange = myExchanges.find(exchange => exchange.name === values.exchangeName) || myExchanges[0];
    const { pair, timeFrame, pattern, exchangeName, settings } = values;
    const { investment, investProfit, limitProfitPercent, stopLossPercent } = settings;

    const newBotValues: NewBot = {
      pair: pair || currentExchange.pairs[0],
      exchangeId: currentExchange?._id || '',
      timeFrame,
      pattern,
      exchangeName: exchangeName || currentExchange.name,
      active: true,
      settings: {
        investment,
        investProfit,
        limitProfitPercent,
        stopLossPercent,
      },
    };
    const newBot = await addNewBot(newBotValues);
    setAllBots({ ...allBots, [newBot._id]: newBot });
  };

  await toast.promise(promiseForTostify(), {
    pending: 'Adding new Bot',
    success: 'Bot added Successfully 👌',
    error: 'Problem adding bot 🤯',
  });
};

const useStyles = makeStyles(theme => ({
  botCardWrapper: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  newButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

const BOT_SUBTITLE_HEIGHT_SPACING = 9;

const Bots: FC<{}> = () => {
  const [allBots, setAllBots] = useRecoilState(allBotsObjAtom);
  const [searchInput, setSearchInput] = useRecoilState(filterBotsStrAtom);
  const [isNewBotDialogOpen, setIsNewBotDialogOpen] = useState<boolean>(false);

  const bots = useRecoilValue(botsByExchangeSelector);
  const myExchanges = useRecoilValue(myExchangesSelector);
  const classes = useStyles();

  useEffect(() => {
    if (isEmpty(allBots)) {
      (async () => {
        const botsByEntity = await getBotsByEntity();
        !isEmpty(botsByEntity) && setAllBots(botsByEntity);
      })();
    }
  }, [allBots, setAllBots]);

  const handleOnActiveChange = async (botId: string, active: boolean) => {
    try {
      await toast.promise(updateBot(botId, { active }), {
        pending: 'Updating Bot',
        success: 'Bot Updated successfully 👌',
        error: 'Problem updating bot 🤯',
      });
      setAllBots({ ...allBots, [botId]: { ...allBots[botId], active } });
    } catch (err) {}
  };

  const handleOnSaveSettings = async (botId: string, settings: BotSettings) => {
    try {
      await toast.promise(updateBot(botId, { settings }), {
        pending: 'Updating Bot',
        success: 'Bot Updated successfully 👌',
        error: 'Problem updating bot 🤯',
      });
      setAllBots({ ...allBots, [botId]: { ...allBots[botId], settings } });
    } catch (err) {}
  };

  const handleOnResearch = () => {};
  const handleOnShowTrades = () => {};

  const handleAddNewBotButtonClick = () => {
    setIsNewBotDialogOpen(true);
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleNewBotDialogCancel = () => {
    setIsNewBotDialogOpen(false);
  };

  const handleOnDeleteBot = async (values: BotFormValues) => {
    const { pair, timeFrame, pattern } = values;
    const description = `Really delete ${pair} - ${timeFrame} - ${startCase(
      pattern.replace('Up', '').replace('Down', ''),
    )}`;
  };

  const handleCreateNewBot = async (values: BotFormValues) => {
    try {
      await addNewBotFromValues(values, myExchanges, allBots, setAllBots);
    } catch (ex) {}
    setIsNewBotDialogOpen(false);
  };

  return (
    <>
      Î
      <SubHeader
        icon={BotIcon}
        title={'Bots'}
        heightSpacing={BOT_SUBTITLE_HEIGHT_SPACING}
        rightSideComponent={
          <OutlinedInput
            type="text"
            id="searchByPair"
            value={searchInput}
            onChange={handleSearchInputChange}
            endAdornment={<SearchIcon />}
          />
        }
      />
      <PageContent heightSpacing={BOT_SUBTITLE_HEIGHT_SPACING}>
        {keys(bots).map(exchangeName => {
          return (
            <div key={exchangeName}>
              <Badge badgeContent={bots[exchangeName].length} color="primary">
                <Chip size={'medium'} label={exchangeName} />
              </Badge>
              {bots[exchangeName].map((bot: BotForUi) => {
                return (
                  <div key={bot._id} className={classes.botCardWrapper}>
                    <BotCard
                      bot={bot}
                      isTrading={!!bot.openTrade}
                      onActiveChange={handleOnActiveChange}
                      onResearch={handleOnResearch}
                      onShowTrades={handleOnShowTrades}
                      onSaveSettings={handleOnSaveSettings}
                      onDeleteBot={handleOnDeleteBot}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
        <Fab onClick={handleAddNewBotButtonClick} color={'secondary'} className={classes.newButton}>
          <AddIcon />
        </Fab>
        <AddNewBotDialog
          isOpen={isNewBotDialogOpen}
          onCancel={handleNewBotDialogCancel}
          onCreateNewBot={handleCreateNewBot}
          exchanges={myExchanges}
        />
      </PageContent>
    </>
  );
};

export default Bots;
