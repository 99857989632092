import { FC, forwardRef, useMemo } from 'react';
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography, Grid, DialogContent, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close as CloseIcon } from '@mui/icons-material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TradeCard } from '@trade4me/components';
import {
  allBotsObjAtom,
  commonBotValues,
  isShowResearchResultsPageAtom,
  myExchangesSelector,
  researchResultsAtom,
} from '../../../../store';
import { addNewBotFromValues } from '..';

export interface ResearchResultsDialogProps {}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
}));

const Transition: FC<any> = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

export const ResearchResultsDialog: FC<ResearchResultsDialogProps> = () => {
  const classes = useStyles();
  const [isShowResearchResultsPage, setIsShowResearchResultsPage] = useRecoilState(isShowResearchResultsPageAtom);
  const { trades, profitAmount, researchStartDate, researchEndDate } = useRecoilValue(researchResultsAtom);
  const researchBotValues = useRecoilValue(commonBotValues);
  const myExchanges = useRecoilValue(myExchangesSelector);
  const [allBots, setAllBots] = useRecoilState(allBotsObjAtom);

  const handleOnClose = () => {
    setIsShowResearchResultsPage(false);
  };
  const handleAddBotButtonClicked = async () => {
    await addNewBotFromValues(researchBotValues, myExchanges, allBots, setAllBots);
  };

  const isAddNewButtonDisabled = useMemo(() => {
    const { pair, timeFrame, pattern } = researchBotValues;
    const exchangeName = researchBotValues.exchangeName || myExchanges[0].name;
    const isBotExists = Object.values(allBots).find(
      bot =>
        bot.exchangeName === exchangeName &&
        bot.timeFrame === timeFrame &&
        bot.pair === pair &&
        bot.pattern === pattern,
    );
    return !!isBotExists;
  }, [researchBotValues, allBots]);

  return (
    <div>
      <Dialog fullScreen open={isShowResearchResultsPage} onClose={handleOnClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Grid container alignItems={'center'}>
              <Grid item>
                <IconButton edge="start" color="inherit" onClick={handleOnClose} aria-label="close" size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
                <Typography variant="h6">Research Results</Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container direction={'column'} spacing={2}>
            <Grid item>
              <div>{profitAmount}</div>
              <div>{researchStartDate}</div>
              <div>{researchEndDate}</div>
            </Grid>

            {trades.map((trade, index) => (
              <Grid item key={index}>
                <TradeCard trade={trade} isResearch={true} />
              </Grid>
            ))}
            <Grid item>
              <Button
                variant={'outlined'}
                disabled={isAddNewButtonDisabled}
                onClick={handleAddBotButtonClicked}
                fullWidth
              >
                Add To Bot
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
